export const COMPETITIONS_KEYS = {
  base: ['competitions'],
  filtered: (filters?: AnyObject): unknown[] => [COMPETITIONS_KEYS.base, filters],
  competition: (id: number): unknown[] => [COMPETITIONS_KEYS.base, id],
  upcoming: (filters: AnyObject): unknown[] => [COMPETITIONS_KEYS.base, 'upcoming', filters],
  teams: (competitionId: number): unknown[] => [COMPETITIONS_KEYS.base, 'teams', competitionId],
  pools: (competitionId: number): unknown[] => [COMPETITIONS_KEYS.base, 'pools', competitionId],
  schedule: (competitionId: number): unknown[] => [COMPETITIONS_KEYS.base, 'schedule', competitionId],
  leaderboard: (competitionId: number): unknown[] => [COMPETITIONS_KEYS.base, 'leaderboard', competitionId],
  bracket: (competitionId: number): unknown[] => [COMPETITIONS_KEYS.base, 'bracket', competitionId],
  game: (gameId: number): unknown[] => [COMPETITIONS_KEYS.base, 'game', gameId],
  resultLogs: ({ gameId, eventId }: { gameId?: number; eventId?: number }) => [
    COMPETITIONS_KEYS.base,
    'resultLogs',
    { gameId, eventId },
  ],
};
