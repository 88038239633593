import React from 'react';
import { autorun } from 'mobx';
import { Clipboard, Calendar, Icon, PlayCircle, Video, Briefcase, Users, Book, MapPin } from 'react-feather';
import { useAuthContextQuery } from 'src/context/authContext/queries/authContextQuery';
import { useLYGAppRoutesContext } from 'src/context/routes';
import { PlayBookIcon } from 'src/shared/ui/assets/PlayBookIcon';
import { useCurrentUserRole } from 'src/context/authContext';

export interface NavigationItemChild {
  id: number | string;
  label: string;
  url: string;
}

export interface NavigationItem {
  children?: NavigationItemChild[];
  icon: Icon | null;
  id: LYGNavItemId;
  label: string;
  url?: string;
}

const lygNavItemsIds = {
  events: 'events',
  entries: 'entries',
  schools: 'schools',
  venues: 'venues',
  sports: 'sports',
  reports: 'reports',
  videos: 'videos',
  photos: 'photos',
  divider: 'divider',
  members: 'members',
} as const;
export type LYGNavItemId = keyof typeof lygNavItemsIds;
export const APPBAR_DIVIDER_ID = lygNavItemsIds.divider;

export function useNavigationItems(): NavigationItem[] {
  const routes = useLYGAppRoutesContext();
  const [isAdmin, setIsAdmin] = React.useState(false);
  const { data: currentUser } = useAuthContextQuery();
  const { isLygVolunteer } = useCurrentUserRole();

  React.useEffect(
    () =>
      autorun(() => {
        if (currentUser?.isLYGAdmin) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      }),
    [currentUser, currentUser?.currentRole],
  );

  return React.useMemo<NavigationItem[]>(
    () => [
      {
        id: lygNavItemsIds.events,
        label: 'Events',
        url: routes.events.base,
        icon: Calendar,
      },

      ...(currentUser?.isLYGAdmin || currentUser?.isAdmin
        ? [
            {
              id: lygNavItemsIds.entries,
              label: 'Entries',
              url: routes.entries.base,
              icon: Users,
            },
          ]
        : []),

      ...(isAdmin
        ? [
            { id: APPBAR_DIVIDER_ID, label: '', icon: null },
            {
              id: lygNavItemsIds.sports,
              label: 'Sports',
              url: routes.sports.base,
              icon: PlayBookIcon,
            },
            {
              id: lygNavItemsIds.schools,
              label: 'Schools',
              url: routes.schools.base,
              icon: Book,
            },
            {
              id: lygNavItemsIds.venues,
              label: 'Venues',
              url: routes.venues.base,
              icon: MapPin,
            },
          ]
        : []),
      ...(!isLygVolunteer
        ? [
            {
              id: lygNavItemsIds.members,
              label: 'Members',
              url: routes.members.base,
              icon: Briefcase,
            },
          ]
        : []),

      ...(currentUser?.isLYGAdmin || currentUser?.isAdmin
        ? [
            { id: APPBAR_DIVIDER_ID, label: '', icon: null },
            {
              id: lygNavItemsIds.reports,
              label: 'Reports',
              url: routes.reports.base,
              icon: Clipboard,
            },
          ]
        : []),

      // { id: APPBAR_DIVIDER_ID, label: '', icon: null },

      // {
      //   id: lygNavItemsIds.videos,
      //   label: 'Videos',
      //   url: routes.videos.base,
      //   icon: PlayCircle,
      // },
      // {
      //   id: lygNavItemsIds.photos,
      //   label: 'Photos',
      //   url: routes.photos.base,
      //   icon: Video,
      // },
    ],
    [routes, isAdmin],
  );
}
