import React from 'react';
import { autorun } from 'mobx';
import { useAuthContextQuery } from 'src/context/authContext/queries/authContextQuery';
import { getJWT, removeJWT } from 'src/context/authContext/utils';
import { CurrentUser, CURRENT_USER_ROLE } from 'src/context/authContext/domain';
import { useAppRoutesContext } from 'src/context/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { FullScreenSpinner } from 'src/shared/ui/layout/FullScreenSpinner';

import { PERMISSIONS_KEY } from 'src/context/authContext/localStorageKeys';

interface AuthContext {
  currentUser: CurrentUser;
  token: AccessToken;
  logout(): void;
}

const AuthCtx = React.createContext<AuthContext | null>(null);

export const AuthContextProvider: React.FC<{ userId?: number }> = ({ children, userId }) => {
  const { data: currentUser } = useAuthContextQuery(userId);
  const routes = useAppRoutesContext();
  const navigate = useNavigate();
  const { state } = useLocation();

  const existentPermissions = JSON.parse(localStorage.getItem(PERMISSIONS_KEY) || 'null');
  const defaultPermissions = React.useMemo(
    () => ({ clubId: currentUser?.currentClub ?? null, userRole: currentUser?.currentRole ?? null }),
    [currentUser?.currentClub, currentUser?.currentRole],
  );

  const token = React.useMemo(
    () => ({
      token: getJWT(),
      ...(existentPermissions || defaultPermissions),
    }),
    [defaultPermissions, existentPermissions],
  );

  React.useEffect(() => {
    if (!token.token) navigate(routes.auth.signIn);

    if (state?.overrideRedirect) return;

    if (currentUser?.actualRole === CURRENT_USER_ROLE.headCoach) {
      if (!currentUser?.onboardingCompleted && location.pathname !== routes.onboarding) {
        navigate(routes.onboarding);
      } else if (
        currentUser?.onboardingCompleted &&
        !currentUser?.onboardingPaymentCompleted &&
        location.pathname !== routes.membership.base
      ) {
        navigate(routes.membership.base);
      }
    }
  }, [
    currentUser?.actualRole,
    currentUser?.onboardingCompleted,
    currentUser?.onboardingPaymentCompleted,
    navigate,
    routes.auth.signIn,
    routes.membership.base,
    routes.onboarding,
    state?.overrideRedirect,
    token.token,
  ]);

  // React.useEffect(() => {

  // }, [currentUser]);

  if (!currentUser) return <FullScreenSpinner />;

  return (
    <AuthCtx.Provider
      value={{
        currentUser,
        token,
        logout: removeJWT,
      }}
    >
      {children}
    </AuthCtx.Provider>
  );
};

export function useAuthContext(): AuthContext {
  const context = React.useContext(AuthCtx);
  if (!context) {
    throw new Error('useAuthContext() cannot be used outside the AuthContextProvider');
  }

  return context;
}

interface UseCurrentUserRoleReturn {
  isAdmin: boolean;
  isHeadCoach: boolean;
  isLygAdmin: boolean;
  isLygBto: boolean;
  isLygBtm: boolean;
  isLygSgo: boolean;
  isLygStm: boolean;
  isLygSgoBto: boolean;
  isReferee: boolean;
  isLygVolunteer: boolean;
}

export function useCurrentUserRole(): UseCurrentUserRoleReturn {
  const { currentUser } = useAuthContext();
  const [role, setRole] = React.useState(currentUser.currentRole);

  React.useEffect(() => autorun(() => setRole(currentUser.currentRole)), [currentUser]);

  return {
    isAdmin: role === CURRENT_USER_ROLE.admin,
    isHeadCoach: role === CURRENT_USER_ROLE.headCoach,
    isLygAdmin: role === CURRENT_USER_ROLE.lygAdmin,
    isLygBto: role === CURRENT_USER_ROLE.lygBto,
    isLygBtm: role === CURRENT_USER_ROLE.lygBtm,
    isLygSgo: role === CURRENT_USER_ROLE.lygSgo,
    isLygStm: role === CURRENT_USER_ROLE.lygStm,
    isLygSgoBto: role === CURRENT_USER_ROLE.lygSgoBto,
    isReferee: role === CURRENT_USER_ROLE.referee,
    isLygVolunteer: role === CURRENT_USER_ROLE.lygVolunteer,
  };
}
