import React from 'react';
import { HelpCircle, Info, X } from 'react-feather';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useOnClickOutside } from 'src/shared/hooks/useOnClickOutside';
import {
  NavigationItem,
  NavigationItemChild,
  useNavigationItems,
  APPBAR_DIVIDER_ID,
} from 'src/lyg/features/appLayout/navigationItems';
import { useSidebarVisibilityContext } from 'src/features/appLayout/SidebarContext';
import { useI18nContext } from 'src/context/i18n';
import { cx } from 'src/shared/utils/common';
import { useCheckNotificationStatus } from 'src/lyg/features/appLayout/checkNotificationStatus';
import { useLYGAppRoutesContext } from 'src/context/routes';
import { LYGLogoutButton } from 'src/lyg/features/appLayout/ui/LYGLogoutButton';
import css from './AppSidebar.module.scss';
import { useCurrentUserRole } from 'src/context/authContext';

interface ChildItemProps {
  item: NavigationItemChild;
}

const ChildItem: React.FC<ChildItemProps> = ({ item }) => {
  const { toggleVisibility } = useSidebarVisibilityContext();
  return (
    <NavLink
      aria-current='page'
      className={({ isActive }) => cx('d-block my-3 my-lg-2', css.childLink, isActive && css.activeChild)}
      to={item.url}
      onClick={toggleVisibility}
    >
      {item.label}
    </NavLink>
  );
};

function hasAnActiveChild(item: NavigationItem, pathname: string): boolean {
  return (item.children && item.children.filter(child => pathname.indexOf(child.url) > -1).length > 0) || false;
}

interface NavItemProps {
  item: NavigationItem;
  hasNotification?: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ item, hasNotification }) => {
  const { pathname } = useLocation();
  const [hasActiveChild, setHasActiveChild] = React.useState(hasAnActiveChild(item, pathname));
  const { toggleVisibility } = useSidebarVisibilityContext();

  React.useEffect(() => {
    setHasActiveChild(hasAnActiveChild(item, pathname));
  }, [item, pathname]);

  return (
    <div key={item.label} className='ms-4 pb-2'>
      {item.url ? (
        <NavLink
          aria-current='page'
          className={({ isActive }) =>
            cx('d-flex align-items-center', css.navLink, (isActive || hasActiveChild) && css.activeLink)
          }
          to={item.url}
          onClick={() => {
            toggleVisibility();
          }}
        >
          {item.icon && <item.icon className={cx('me-3 flex-shrink-0', css.icon)} />}{' '}
          <div className='position-relative'>
            {item.label}
            {hasNotification && <span className={css.notification} />}
          </div>
        </NavLink>
      ) : (
        <button
          className={cx('d-flex align-items-center p-0 bg-transparent', css.navLink, hasActiveChild && css.activeLink)}
          type='button'
          onClick={() => {
            toggleVisibility();
          }}
        >
          {item.icon && <item.icon className={cx('me-3 flex-shrink-0', css.icon)} />} <div>{item.label}</div>
        </button>
      )}
      <div className={cx('overflow-hidden', css.childItems, false && css.expandedChildren)}>
        {item.children?.map(child => (
          <ChildItem key={child.url} item={child} />
        ))}
      </div>
    </div>
  );
};

const Navigation: React.FC = () => {
  const navigationItems = useNavigationItems();
  const notificationStatus = useCheckNotificationStatus();

  return (
    <nav className={cx('flex-grow-1', css.navigation)}>
      {navigationItems.map((item, i) =>
        item.id === APPBAR_DIVIDER_ID ? (
          <div key={item.id + i} className='ts-h-divider ms-2 mb-3' />
        ) : (
          <NavItem key={item.id} item={item} hasNotification={notificationStatus[item.id]} />
        ),
      )}
    </nav>
  );
};

export const AppSidebar: React.FC = () => {
  const { hidden, toggleVisibility, isTopbarDisplay } = useSidebarVisibilityContext();
  const { dashboard, feedback, faqCentre } = useLYGAppRoutesContext();
  const { isLygAdmin, isAdmin } = useCurrentUserRole();
  const i18n = useI18nContext();
  const ref = useOnClickOutside(!hidden, toggleVisibility);

  return (
    <aside
      className={cx(
        'position-fixed mw-100 h-100',
        css.sidebarWrapper,
        hidden && css.wrapperHidden,
        isTopbarDisplay && css.wrapperWithTopbar,
      )}
    >
      <div
        className={cx(
          'h-100 d-flex flex-column',
          css.sidebar,
          hidden && css.sidebarHidden,
          isTopbarDisplay && hidden && css.sidebarWithTopbar,
        )}
        ref={ref}
      >
        <div className={cx('d-flex align-items-center', css.logoWrapper)}>
          <Link
            to={dashboard}
            aria-label={i18n.t('shared.navigation.dashboard')}
            title={i18n.t('shared.navigation.dashboard')}
            onClick={toggleVisibility}
          >
            <img
              width={100}
              // src={isLygAdmin || isAdmin ? '/assets/lyg/lyg.png' : '/assets/lyg/lyg-network.jpg'}
              src={'/assets/lyg/lyg.png'}
              alt='London Youth Games Logo'
              className={cx('flex-shrink-0 user-select-none')}
            />
          </Link>
          <button
            className='bg-transparent py-3 ms-auto'
            type='button'
            onClick={toggleVisibility}
            name='Close menu'
            aria-label='Close menu'
          >
            <X />
          </button>
        </div>
        <Navigation />
        <Link className={cx('d-flex align-items-center p-0 bg-transparent ms-4 my-3', css.navLink)} to={faqCentre.base}>
          <Info className={cx('me-3 flex-shrink-0', css.icon)} />
          FAQ
        </Link>
        <Link className={cx('d-flex align-items-center p-0 bg-transparent ms-4 my-3', css.navLink)} to={feedback.base}>
          <HelpCircle className={cx('me-3 flex-shrink-0', css.icon)} />
          Help
        </Link>
        <LYGLogoutButton />
      </div>
    </aside>
  );
};
