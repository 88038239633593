import { Observer } from 'mobx-react-lite';
import React, { ReactPortal } from 'react';
import ReactDOM from 'react-dom';
import { Bell, ChevronUp } from 'react-feather';
import { CSS_BREAKPOINTS } from 'src/constants';
import { useI18nContext } from 'src/context/i18n';
import {
  EnableNotificationsButton,
  SafariEnableNotificationsPrompt,
} from 'src/lyg/features/appLayout/ui/AppNotifications/SafariEnableNotificationsPrompt';
import { SafariNotificationModel } from 'src/features/appLayout/ui/AppNotifications/SafariNotificationsModel';
import { useIsMobile } from 'src/shared/hooks/useIsMobile';
import { useOnClickOutside } from 'src/shared/hooks/useOnClickOutside';
import { browserIsSafari, cx } from 'src/shared/utils/common';
import css from './index.module.scss';

interface NotificationsWrapperProps {
  isMobile: boolean;
  children: JSX.Element;
}

const NotificationsWrapper = ({ children, isMobile }: NotificationsWrapperProps): ReactPortal | JSX.Element =>
  isMobile ? ReactDOM.createPortal(children, document.getElementById('root') as HTMLElement) : children;

export const LYGAppNotifications: React.FC = () => {
  const [opened, setOpened] = React.useState(false);
  const [safariHandler] = React.useState(new SafariNotificationModel());

  const closeTray = () => setOpened(false);
  const ref = useOnClickOutside(opened, closeTray);

  const isMobile = useIsMobile(CSS_BREAKPOINTS.sm);
  const i18n = useI18nContext();
  const label = opened ? i18n.t('shared.phrases.notificationsClose') : i18n.t('shared.phrases.notificationsOpen');

  // const notificationsQuery = useNotificationsQuery(0);

  React.useLayoutEffect(() => {
    opened && isMobile
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden');
  }, [opened, isMobile]);

  return (
    <div className='position-relative' ref={ref}>
      <button
        aria-label={label}
        className={cx('p-0 bg-transparent', opened && 'ts-color-primary')}
        title={label}
        type='button'
        onClick={opened ? closeTray : () => setOpened(true)}
      >
        <Bell />
        {/* {!!notificationsQuery.data?.totalCount && (
          <div className={css.badge} title={i18n.t('shared.phrases.notificationsNew')} />
        )} */}
      </button>
      <Observer>
        {() => (safariHandler.shouldShowPrompt ? <SafariEnableNotificationsPrompt model={safariHandler} /> : null)}
      </Observer>
      <NotificationsWrapper isMobile={isMobile}>
        <div
          className={cx(
            isMobile ? 'position-fixed start-0 left-0 w-100 text-break' : 'position-absolute end-0 pb-4',
            !opened && css.trayClosed,
            'px-4',
            css.tray,
          )}
          ref={ref}
        >
          <div className={cx('d-flex justify-content-between align-items-center w-100', css.header)}>
            <div className='ts-fs-18 ts-fw-500'>{i18n.t('shared.phrases.notifications')}</div>
            {/* {!!notificationsQuery.data?.totalCount && (
              <TransparentButton className={css.clearAllBtn} onClick={() => null}>
                {i18n.t('shared.phrases.clearAll')}
              </TransparentButton>
            )} */}
          </div>
          <Observer>
            {() =>
              browserIsSafari() && !safariHandler.notificationsAlreadyEnabled ? (
                <div className='w-100 mb-3'>
                  <EnableNotificationsButton model={safariHandler} />
                </div>
              ) : null
            }
          </Observer>

          <div
            className={cx(
              css.notificationsBox,
              'd-flex flex-column',
              // !notificationsQuery.data?.totalCount && 'justify-content-center ',
            )}
          >
            {/* <QueryLoader
              // query={notificationsQuery}
              onLoading={<RippleAnimation className='m-auto d-block ts-color-primary' />}
            >
              {({ data }) =>
                data.totalCount > 0 ? (
                  data.notifications.map((notification, i) => (
                    <React.Fragment key={notification.id}>
                      <NotificationItem notification={notification} onNavigate={() => setOpened(false)} />
                      {i !== data.notifications.length - 1 && <div className={cx(css.divider, 'w-100 my-3')} />}
                    </React.Fragment>
                  ))
                ) : (
                  <NoNotificationsMessage />
                )
              }
            </QueryLoader> */}
          </div>

          {isMobile && (
            <div className={cx('w-100 text-center', css.closeTrayBtnWrapper)}>
              <button onClick={closeTray}>
                <ChevronUp className='ts-color-primary' />
              </button>
            </div>
          )}
        </div>
      </NotificationsWrapper>
    </div>
  );
};
