import React from 'react';
import { observer } from 'mobx-react-lite';
import { useCurrentUserRole } from 'src/context/authContext';

interface CurrentUserIsProps {
  LYGadmin?: boolean;
  bto?: boolean;
  sgo?: boolean;
  sgobto?: boolean;
  admin?: boolean;
  volunteer?: boolean;
  elseRender?: JSX.Element | null;
}

export const RenderIfCurrentUserIs: React.FC<RequireMinOne<CurrentUserIsProps>> = observer(
  ({ children, LYGadmin, bto, sgo, sgobto, admin, volunteer, elseRender }) => {
    const { isAdmin, isLygAdmin, isLygBto, isLygSgo, isLygSgoBto, isLygVolunteer } = useCurrentUserRole();
    if (
      (LYGadmin && isLygAdmin) ||
      (bto && isLygBto) ||
      (isAdmin && admin) ||
      (sgo && isLygSgo) ||
      (sgobto && isLygSgoBto) ||
      (volunteer && isLygVolunteer)
    ) {
      return <>{children}</>;
    }

    return elseRender || null;
  },
);
