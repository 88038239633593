import React from 'react';

export const LYG_SUBDOMAIN = 'lyg' as const;

export const LYG_APP_ROUTES = {
  auth: {
    resetPassword: '/resetpassword',
    signIn: '/login',
    updatePassword: '/update_password',
  },
  competitions: {
    base: `competitions`,
    competition: (eventId: number, competitionId: number) => `/events/${eventId}/competitions/${competitionId}/pools`,
    schedule: (eventId: number, competitionId: number) => `/events/${eventId}/competitions/${competitionId}/schedule`,
    bracket: (eventId: number, competitionId: number) => `/events/${eventId}/competitions/${competitionId}/bracket`,
  },
  dashboard: '/',
  events: {
    base: '/events',
    event: (id: number) => `/events/${id}`,
    edit: (id: number) => `/events/${id}/edit`,
    create: '/events/create',
  },
  entries: {
    base: '/entries',
  },
  openGamesEntries: {
    edit: (id: number) => `/entries/open-games/${id}/edit`,
  },
  schoolGamesEntries: {
    edit: (id: number) => `/entries/school/${id}/edit`,
  },
  schools: {
    base: '/schools',
    event: (id: number) => `/schools/${id}`,
    edit: (id: number) => `/schools/${id}/edit`,
    create: '/schools/create',
  },
  venues: {
    base: '/venues',
    member: (id: number) => `/venues/${id}`,
    edit: (id: number) => `/venues/${id}/edit`,
    create: '/venues/create',
  },
  teams: {
    base: '/teams',
  },
  members: {
    base: '/members',
    member: (id: number) => `/members/${id}`,
    edit: (id: number) => `/members/${id}/edit`,
    create: '/members/create',
  },
  sports: {
    base: '/sports',
    member: (id: number) => `/sports/${id}`,
    edit: (id: number) => `/sports/${id}/edit`,
    create: '/sports/create',
  },
  feedback: {
    base: '/feedback',
  },
  faqCentre: {
    base: '/faq',
  },
  videos: {
    base: '/videos',
  },
  photos: {
    base: '/photos',
  },
  reports: {
    base: '/reports',
  },
  settings: {
    base: '/settings',
  },
} as const;

const LYGAppRoutesContext = React.createContext(LYG_APP_ROUTES);

export type LYGAppRoutes = typeof LYG_APP_ROUTES;

export function useLYGAppRoutesContext(): LYGAppRoutes {
  return React.useContext(LYGAppRoutesContext);
}

export const APP_ROUTES = {
  auth: {
    resetPassword: '/resetpassword',
    signIn: '/login',
    signUp: '/signup',
    updatePassword: '/update_password',
  },
  billing: {
    base: '/billing',
  },
  chat: {
    base: '/chat',
  },
  coaches: {
    base: '/coaches',
    createCoach: '/coaches/create',
    coach: (coachId: number): string => `/coaches/${coachId}`,
    editCoach: (coachId: number): string => `/coaches/${coachId}/edit`,
  },
  clubs: {
    base: '/clubs',
    create: '/clubs/create',
    settings: (clubId: number): string => `/clubs/${clubId}/settings`,
    edit: (clubId: number): string => `/clubs/${clubId}/edit`,
  },
  clubWebsite: {
    base: '/club-website',
  },
  dashboard: '/',
  competitions: {
    base: `competition`,
    competition: (eventId: number, competitionId: number) =>
      `/calendar/competitions/${eventId}/competition/${competitionId}/pools`,
    schedule: (eventId: number, competitionId: number) =>
      `/calendar/competitions/${eventId}/competition/${competitionId}/schedule`,
    bracket: (eventId: number, competitionId: number) =>
      `/calendar/competitions/${eventId}/competition/${competitionId}/bracket`,
    game: (eventId: number, competitionId: number, gameId: number) =>
      `/calendar/competitions/${eventId}/competition/${competitionId}/game/${gameId}`,
    liveEditor: (eventId: number, competitionId: number, gameId: number) =>
      `/calendar/competitions/${eventId}/competition/${competitionId}/game/${gameId}/live`,
    scoreBoard: (gameId: number) => `score/${gameId}`,
  },
  events: {
    competitions: {
      base: '/calendar/competitions/',
      create: '/calendar/competitions/create',
      editEvent: (eventId: number): string => `/calendar/competitions/${eventId}/edit`,
      event: (eventId: number): string => `/calendar/competitions/${eventId}`,
      competition: (eventId: number, competitionId: number): string =>
        `/calendar/competitions/${eventId}/competition/${competitionId}`,
      schedule: (eventId: number, competitionId: number): string =>
        `/calendar/competitions/${eventId}/competition/${competitionId}/schedule`,
      bracket: (eventId: number, competitionId: number): string =>
        `/calendar/competitions/${eventId}/competition/${competitionId}/bracket`,
    },
    base: '/calendar',
    createEvent: '/calendar/create',
    import: '/calendar/import',
    editEvent: (eventId: number): string => `/calendar/${eventId}/edit`,
    event: (eventId: number): string => `/calendar/${eventId}`,
    editResults: (eventId: number): string => `/calendar/${eventId}/results/edit`,
    report: (eventId: number): string => `/calendar/${eventId}/report`,
  },
  entries: {
    base: '/competition-entries',
    edit: (entryId: number): string => `/competition-entries/${entryId}/edit`,
    create: '/competition-entries/create',
  },
  exercises: {
    base: '/exercises',
    create: '/exercises/create',
    edit: (exerciseId: number) => `/exercises/${exerciseId}/edit`,
    configure: (exerciseId: number) => `/exercises/${exerciseId}/configure`,
  },
  faqCentre: '/faq',
  feedbackCentre: '/feedback',
  highlights: {
    archive: `/video-clips/archive`,
    base: '/video-clips',
    edit: (highlightId: number): string => `/video-clips/${highlightId}/edit`,
    share: (highlightId: number[]): string => `/video-clips/share/${JSON.stringify(highlightId)}`,
  },
  membership: {
    base: '/membership',
    frictions: '/membership?frictions=true&tab=payment',
  },
  onboarding: '/onboarding',
  plans: '/plans',
  players: {
    base: '/players',
    create: '/players/create',
    edit: (playerId: number): string => `/players/${playerId}/edit`,
    player: (playerId: number): string => `/players/${playerId}`,
    playerNotes: (playerId: number): string => `${APP_ROUTES.players.player(playerId)}#notes`,
  },
  reports: {
    base: '/reports',
    print: ({ teamId, reportType, dateRange }: { teamId: number | null; reportType: string; dateRange: string }) =>
      `/reports/${reportType}/${teamId}/${dateRange}/print`,
  },
  trainingStats: '/stats/training',
  gameStats: {
    base: '/stats/game',
    event: (eventId: number): string => `/stats/game/${eventId}`,
    liveEditor: (eventId: number): string => `/stats/game/${eventId}/live`,
  },
  products: {
    base: '/products',
    create: '/products/create',
    edit: (productId: number) => `/products/${productId}/edit`,
    payment: (productId: number) => `/products/${productId}`,
    setup: '/products/setup',
  },
  settings: {
    base: '/settings',
  },
  targets: {
    base: '/targets',
    create: '/targets/create',
    edit: (targetId: number): string => `/targets/${targetId}/edit`,
  },
  teams: {
    base: '/teams',
    createTeam: '/teams/create',
    editTeam: (teamId: number): string => `/teams/${teamId}/edit`,
    team: (teamId: number): string => `/teams/${teamId}`,
    invite: (teamId: number): string => `/teams/${teamId}/invite`,
  },
  videos: {
    base: '/videos/library',
    create: '/videos/library/create',
    editVideo: (videoId: number): string => `/videos/library/${videoId}/edit`,
    videoEditor: (videoId: number): string => `/videos/library/${videoId}`,
  },
} as const;

const AppRoutesContext = React.createContext(APP_ROUTES);

export type AppRoutes = typeof APP_ROUTES;

export function useAppRoutesContext(): AppRoutes {
  return React.useContext(AppRoutesContext);
}
