import React from 'react';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import { LYGAuthRoute, LYGCommonRoute } from 'src/lyg/LYGRoutesHelpers';
import { LYG_APP_ROUTES } from 'src/context/routes';
import { sendGAPageView } from 'src/shared/hooks/useAnalytics';
import { ErrorPage } from 'src/shared/ui/utils/ErrorPage';
import { RenderIfCurrentUserIs } from 'src/lyg/utils/RenderIfCurrentUserIs';
import { LYGUnauthorisedPage } from 'src/lyg/utils/LYGUnauthorisedPage';
import {
  SignInPage,
  ResetPasswordPage,
  UpdatePasswordPage,
  DashboardPage,
  CompetitionPoolsPage,
  EventsHomePage,
  EventPage,
  EventEditPage,
  EventCreatePage,
  EntriesHomePage,
  TeamsHomePage,
  MembersHomePage,
  MemberEditPage,
  MemberPage,
  MemberCreatePage,
  VenuesHomePage,
  VenueEditPage,
  VenueCreatePage,
  SchoolsHomePage,
  SchoolEditPage,
  SchoolCreatePage,
  SportsHomePage,
  SportEditPage,
  SportCreatePage,
  ReportsHomePage,
  VideosHomePage,
  PhotosHomePage,
  SettingsHomePage,
  FAQsPage,
  FeedbackPage,
  NotFoundPage,
  CompetitionSchedulePage,
} from 'src/lyg';
import { CompetitionBracketPage } from 'src/lyg/pages/competitions/bracket';
import { CompetitionDetailsPage } from 'src/lyg/pages/competitions/details';
import { EditOpenGamesEntryPage } from 'src/lyg/pages/entries/edit/openGames';
import { EditSchoolGamesEntryPage } from 'src/lyg/pages/entries/edit/schoolGames';
import { PublicQrPage } from 'src/lyg/pages/competitions/details/PublicQrPage';

export const lygRouter = createBrowserRouter([
  {
    path: '/',
    element: <LYGCommonRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: LYG_APP_ROUTES.auth.signIn,
        element: <SignInPage />,
        loader: sendGAPageView,
      },
      {
        path: LYG_APP_ROUTES.auth.resetPassword,
        element: <ResetPasswordPage />,
        loader: sendGAPageView,
      },
      {
        path: LYG_APP_ROUTES.auth.updatePassword,
        element: <UpdatePasswordPage />,
        loader: sendGAPageView,
      },
      {
        path: 'competitions/:competitionId',
        element: <CompetitionDetailsPage />,
      },
      {
        path: 'competitions/:competitionId/qr',
        element: <PublicQrPage />,
      },
      {
        element: <LYGAuthRoute />,
        children: [
          {
            path: LYG_APP_ROUTES.dashboard,
            element: <DashboardPage />,
            loader: sendGAPageView,
          },
          {
            path: LYG_APP_ROUTES.events.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <EventsHomePage />,
                loader: sendGAPageView,
              },
              {
                path: ':eventId',
                element: <Outlet />,
                loader: sendGAPageView,
                children: [
                  {
                    index: true,
                    element: <EventPage />,
                    loader: sendGAPageView,
                  },
                  {
                    path: 'competitions',
                    element: <Outlet />,
                    children: [
                      {
                        path: ':competitionId/pools',
                        element: (
                          <RenderIfCurrentUserIs LYGadmin admin elseRender={<LYGUnauthorisedPage />}>
                            <CompetitionPoolsPage />
                          </RenderIfCurrentUserIs>
                        ),
                        loader: sendGAPageView,
                      },
                      {
                        path: ':competitionId/schedule',
                        element: <CompetitionSchedulePage />,
                        loader: sendGAPageView,
                      },
                      {
                        path: ':competitionId/bracket',
                        element: <CompetitionBracketPage />,
                        loader: sendGAPageView,
                      },
                    ],
                  },
                ],
              },
              {
                path: ':eventId/edit',
                element: (
                  <RenderIfCurrentUserIs LYGadmin admin elseRender={<LYGUnauthorisedPage />}>
                    <EventEditPage />
                  </RenderIfCurrentUserIs>
                ),
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: (
                  <RenderIfCurrentUserIs LYGadmin admin elseRender={<LYGUnauthorisedPage />}>
                    <EventCreatePage />
                  </RenderIfCurrentUserIs>
                ),
                loader: sendGAPageView,
              },
            ],
          },
          {
            path: LYG_APP_ROUTES.entries.base,
            element: (
              <RenderIfCurrentUserIs LYGadmin admin bto sgo sgobto elseRender={<LYGUnauthorisedPage />}>
                <Outlet />
              </RenderIfCurrentUserIs>
            ),
            children: [
              {
                index: true,
                element: <EntriesHomePage />,
                loader: sendGAPageView,
              },
              {
                path: 'open-games/:entryId/edit',
                element: <EditOpenGamesEntryPage />,
                loader: sendGAPageView,
              },
            ],
          },
          {
            path: LYG_APP_ROUTES.entries.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <EntriesHomePage />,
                loader: sendGAPageView,
              },
              {
                path: 'school/:entryId/edit',
                element: <EditSchoolGamesEntryPage />,
                loader: sendGAPageView,
              },
            ],
          },
          {
            path: LYG_APP_ROUTES.teams.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <TeamsHomePage />,
                loader: sendGAPageView,
              },
            ],
          },
          {
            path: LYG_APP_ROUTES.members.base,
            element: (
              <RenderIfCurrentUserIs LYGadmin admin bto sgo sgobto elseRender={<LYGUnauthorisedPage />}>
                <Outlet />
              </RenderIfCurrentUserIs>
            ),
            children: [
              {
                index: true,
                element: <MembersHomePage />,
                loader: sendGAPageView,
              },
              {
                path: ':memberId/edit',
                element: (
                  <RenderIfCurrentUserIs LYGadmin admin bto sgo sgobto elseRender={<LYGUnauthorisedPage />}>
                    <MemberEditPage />
                  </RenderIfCurrentUserIs>
                ),
                loader: sendGAPageView,
              },
              {
                path: ':memberId',
                element: <MemberPage />,
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: (
                  <RenderIfCurrentUserIs LYGadmin admin bto sgo sgobto elseRender={<LYGUnauthorisedPage />}>
                    <MemberCreatePage />
                  </RenderIfCurrentUserIs>
                ),
                loader: sendGAPageView,
              },
            ],
          },
          {
            path: LYG_APP_ROUTES.venues.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <VenuesHomePage />,
                loader: sendGAPageView,
              },
              {
                path: ':venueId/edit',
                element: (
                  <RenderIfCurrentUserIs LYGadmin admin elseRender={<LYGUnauthorisedPage />}>
                    <VenueEditPage />
                  </RenderIfCurrentUserIs>
                ),
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: (
                  <RenderIfCurrentUserIs LYGadmin admin elseRender={<LYGUnauthorisedPage />}>
                    <VenueCreatePage />
                  </RenderIfCurrentUserIs>
                ),
                loader: sendGAPageView,
              },
            ],
          },
          {
            path: LYG_APP_ROUTES.schools.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <SchoolsHomePage />,
                loader: sendGAPageView,
              },
              {
                path: ':schoolId/edit',
                element: (
                  <RenderIfCurrentUserIs LYGadmin admin elseRender={<LYGUnauthorisedPage />}>
                    <SchoolEditPage />
                  </RenderIfCurrentUserIs>
                ),
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: (
                  <RenderIfCurrentUserIs LYGadmin admin elseRender={<LYGUnauthorisedPage />}>
                    <SchoolCreatePage />
                  </RenderIfCurrentUserIs>
                ),
                loader: sendGAPageView,
              },
            ],
          },
          {
            path: LYG_APP_ROUTES.sports.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <SportsHomePage />,
                loader: sendGAPageView,
              },
              {
                path: ':sportId/edit',
                element: (
                  <RenderIfCurrentUserIs LYGadmin admin elseRender={<LYGUnauthorisedPage />}>
                    <SportEditPage />
                  </RenderIfCurrentUserIs>
                ),
                loader: sendGAPageView,
              },
              {
                path: 'create',
                element: (
                  <RenderIfCurrentUserIs LYGadmin admin elseRender={<LYGUnauthorisedPage />}>
                    <SportCreatePage />
                  </RenderIfCurrentUserIs>
                ),
                loader: sendGAPageView,
              },
            ],
          },
          {
            path: LYG_APP_ROUTES.reports.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <ReportsHomePage />,
                loader: sendGAPageView,
              },
            ],
          },
          {
            path: LYG_APP_ROUTES.videos.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <VideosHomePage />,
                loader: sendGAPageView,
              },
            ],
          },
          {
            path: LYG_APP_ROUTES.photos.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <PhotosHomePage />,
                loader: sendGAPageView,
              },
            ],
          },
          {
            path: LYG_APP_ROUTES.settings.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <SettingsHomePage />,
                loader: sendGAPageView,
              },
            ],
          },
          {
            path: LYG_APP_ROUTES.faqCentre.base,
            element: <FAQsPage />,
            loader: sendGAPageView,
          },
          {
            path: LYG_APP_ROUTES.feedback.base,
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <FeedbackPage />,
                loader: sendGAPageView,
              },
            ],
          },
          /* 404 */
          {
            path: '*',
            element: <NotFoundPage />,
            loader: sendGAPageView,
          },
        ],
      },
    ],
  },
]);
